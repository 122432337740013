var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('trev-cool-table',{attrs:{"courier":_vm.$courier.Companies,"title":'Companies',"headers":_vm.headers,"addNewLink":{ name: 'Company Add' }},scopedSlots:_vm._u([{key:"item.Options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{
        name: 'Company Dashboard',
        params: { companyId: item.id },
      }}},[_vm._v(" View "),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-magnify")])],1)]}},{key:"item.website",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"info--text",attrs:{"text":"","color":"lighten-5 info","href":item.website,"target":"_blank"}},[_vm._v(" "+_vm._s(item.website)+" ")])]}},{key:"item.dateAdded",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.dateAdded).toLocaleString("en-GB"))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }