<template>
  <trev-cool-table
    :courier="$courier.Companies"
    :title="'Companies'"
    :headers="headers"
    :addNewLink="{ name: 'Company Add' }"
  >
    <template v-slot:item.Options="{ item }">
      <v-btn
        small
        color="primary"
        :to="{
          name: 'Company Dashboard',
          params: { companyId: item.id },
        }"
      >
        View
        <v-spacer></v-spacer>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.website="{ item }">
      <v-btn
        text
        color="lighten-5 info"
        class="info--text"
        :href="item.website"
        target="_blank"
      >
        {{ item.website }}
      </v-btn>
    </template>
    <template v-slot:item.dateAdded="{ item }">
      {{ new Date(item.dateAdded).toLocaleString("en-GB") }}
    </template>
  </trev-cool-table>
</template>

<script>
import trevCoolTable from "../../../../components/tables/trev-cool-table.vue";
export default {
  components: { trevCoolTable },
  metaInfo() {
    return {
      title: "Companies",
    };
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Website",
        align: "start",
        sortable: false,
        value: "website",
      },
      // {
      //   text: "Support",
      //   align: "start",
      //   sortable: false,
      //   value: "supportText",
      // },
      {
        text: "Date Added",
        align: "start",
        sortable: false,
        value: "dateAdded",
      },
      {
        text: "Options",
        align: "start",
        sortable: false,
        value: "Options",
      },
    ],
  }),
};
</script>

<style>
</style>